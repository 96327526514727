<typography-v2 *ngIf="props.label" class="v2-mb-1">{{ props.label }}</typography-v2>

<slider
  (setRange)="change($event)"
  [min]="props.values.min_value"
  [max]="props.values.max_value"
  [from]="formControl.value?.length ? formControl.value[0] : null"
  [to]="formControl.value?.length ? formControl.value[1] : null"
  [step]="1"
  [attr.data-id]="field.key"
  type="minutes"
></slider>
